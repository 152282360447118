<template lang="pug">
article.category-cap(:class="[isNeedCrumbs ? 'category-cap_crumbs' : '']")
  .category-cap__cont.cont
    h1.category-cap__caption.caption {{caption}}
    .category-cap__list(v-if ="subcategories && subcategories.length")
      router-link.category-cap__item(:to = "{ name: 'Catalog', params: { slug: subcategory.slug }}", v-for="subcategory in subcategories")
        picture.category-cap__picture(v-if="subcategory.image")
          source(:srcset="`${subcategory.image.w59} 1x, ${subcategory.image.w118} 2x`", type="image/webp", media="(max-width: 1700px)")
          source(:srcset="`${subcategory.image.w80} 1x, ${subcategory.image.w160} 2x`", type="image/webp")
          source(:srcset="`${subcategory.image.j80} 1x, ${subcategory.image.j160} 2x`")
          img.category-cap__image(:src="`${subcategory.image.j80}`", alt="", loading="lazy")
        span.category-cap__name {{subcategory.name}}
</template>

<script>
import '@/assets/styles/components/category-cap.sass'

export default {
  name: 'CategoryCap',
  props: {
    categoryName: String,
    subcategories: Array,
    isNeedCrumbs: Boolean,
    currentPage: Number
  },
  computed: {
    caption () {
      return this.currentPage > 1 ? `${this.categoryName} - страница ${this.currentPage}` : this.categoryName
    }
  }
}
</script>
