<template lang="pug">
head-meta(:title-page="titlePage", :description="descriptionPage", :og="og")
spinner(v-if="isLoading")
template(v-else)
  template(v-if="!isNotFound")
    category-cap(:category-name = "categoryName", :subcategories="subcategories", :current-page="currentPage")
    filters-line(
      v-if="filters.length",
      :filters-start="filters",
      :sort="sort",
      :products="products",
      :product-count="productCount",
      :groups="groups",
      :color-count="colorCount",
      @sort-products="sortProducts",
      @apply-filters="applyFilters")
    tile-line(
      :pages="pages",
      :current-page="currentPage",
      :products="products",
      :is-loading-button="isLoadingButton"
      @show-more="showMore"
      @update-page="updatePage"
    )
  thanks404(v-else, mod="error")
</template>

<script>
import HeadMeta from '../components/_layout/HeadMeta'
import Crumbs from '../components/Crumbs'
import Spinner from '../components/Spinner'
import Thanks404 from '../components/Thanks404'
import CategoryCap from '../components/category/CategoryCap'
import FiltersLine from '../components/FiltersLine'
import TileLine from '../components/_tiles/TileLine'
import {
  getCategory,
  getProductsCategory
} from '../assets/scripts/Api'

import { url } from '../assets/scripts/variables'

export default {
  name: 'CatalogPage',
  components: { Thanks404, TileLine, FiltersLine, HeadMeta, Crumbs, CategoryCap, Spinner },
  data () {
    return {
      categoryName: '',
      categoryImage: '',
      subcategories: [],
      products: [],
      startProducts: [],
      startPage: 1,
      currentPage: 1,
      pages: 1,
      filters: [],
      selectedFilters: {},
      sort: '',
      productCount: null,
      isLoading: false,
      isNotFound: false,
      isLoadingButton: false,
      groups: []
    }
  },
  computed: {
    titlePage () {
      if (this.isNotFound) return 'Страница не найдена'
      if (this.categoryName) {
        return this.currentPage > 1
          ? `${this.categoryName} купить в каталоге интернет магазина ChiedoHome - страница ${this.currentPage}`
          : `${this.categoryName} купить в каталоге интернет магазина ChiedoHome`
      } else {
        return ''
      }
    },
    descriptionPage () {
      if (this.isNotFound) return 'Проверьте ссылку на страницу, возможно она была удалена или перемещена'
      if (this.categoryName) {
        return this.currentPage > 1
          ? `Выгодные цены на товары каталога ${this.categoryName} на chiedohome. Быстрая доставка, отзывы, гарантия - страница ${this.currentPage}`
          : `Выгодные цены на товары каталога ${this.categoryName} на chiedohome. Быстрая доставка, отзывы, гарантия`
      } else {
        return ''
      }
    },
    og () {
      if (this.isNotFound) return null
      if (!this.categoryName && !this.products.length) return null
      const itemListElement = this.products.map(product => {
        return {
          '@type': 'Offer',
          name: product.name,
          description: product?.description,
          url: url + this.$router.resolve({
            name: 'Product',
            params: {
              slug: product.slug
            }
          }).path,
          price: product.price_current,
          priceCurrency: 'RUB',
          image: product.image.j584,
          availability: product?.stock ? 'http://schema.org/InStock' : 'https://schema.org/BackOrder'
        }
      })
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'OfferCatalog',
        name: this.categoryName,
        description: `Выгодные цены на товары каталога ${this.categoryName} на chiedohome. Быстрая доставка, отзывы, гарантия`,
        image: this?.categoryImage,
        itemListElement: itemListElement
      })
    },
    colorCount () {
      if (this.filters) {
        const colorFilters = this.filters.find(item => item.slug === 'tsvet')
        if (!colorFilters) return 0
        return colorFilters.list.filter(item => item.is_current).length
      }
      return 0
    }
  },
  methods: {
    async getDataPage (slug, page = 1) {
      this.isLoading = true
      const data = await getProductsCategory(slug, page, this.sort, this.selectedFilters)
      this.isLoading = false
      this.placeProductsData(data)
    },
    async getSubcategories (slug) {
      const data = await getCategory(slug)
      this.placeSubcategoryData(data)
    },
    applyFilters (filters) {
      this.currentPage = 1
      this.selectedFilters = filters
      this.startProducts = []
      this.getDataPage(this.$route.params.slug)
    },
    sortProducts (sort) {
      this.sort = sort
      this.refreshProducts()
    },
    refreshProducts () {
      if (this.currentPage === 1) {
        this.$router.push({ name: this.$route.name, params: { slug: this.$route.params.slug, page: '', numberPage: '' }, query: { sort: this.sort } })
      } else {
        this.$router.push({ name: this.$route.name, params: { slug: this.$route.params.slug, page: 'page', numberPage: this.currentPage }, query: { sort: this.sort } })
      }
    },
    isNeedErrorRedirect (to) {
      const page = to.params.page
      const number = to.params.numberPage
      if ((page && !number) || (!page && number)) {
        this.isNotFound = true
        return true
      }
      return false
    },
    getPreloadSubcategories (route) {
      const app = document.querySelector('#app')
      const category = app.dataset.category
      if (category && JSON.parse(category).slug === route.params.slug) {
        return this.placeSubcategoryData(JSON.parse(category))
      }
      this.getSubcategories(route.params.slug)
    },
    getParseProducts () {
      const app = document.querySelector('#app')
      const products = app.dataset.categoryProducts
      return products ? JSON.parse(products) : null
    },
    checkPreloadProducts (route) {
      const parseProducts = this.getParseProducts()
      const pageFromRoute = +route.params.numberPage || 1
      const sort = route.query.sort || ''
      const slug = route.params.slug
      const isEmptyFilters = !Object.keys(this.selectedFilters).length
      return parseProducts && parseProducts.page === pageFromRoute && parseProducts.sort === sort && parseProducts.category_slug === slug && isEmptyFilters
    },
    setProducts (route, page) {
      if (this.checkPreloadProducts(route)) {
        const parseProducts = this.getParseProducts()
        this.placeProductsData(parseProducts)
      } else {
        this.getDataPage(route.params.slug, page)
      }
    },
    placeSubcategoryData (data) {
      this.categoryName = data.name
      this.categoryImage = data.image
      this.subcategories = data.subcategories
      this.groups = data.groups
    },
    placeProductsData (data) {
      if (data.status === 'error') this.isNotFound = true
      this.products = data.products
      this.currentPage = data.page || 1
      this.pages = data.page_count
      this.filters = data.filters
      this.categoryName = data.category_name
      this.productCount = data.products_count
      if (!this.startProducts.length) {
        this.startProducts = this.products
        this.startPage = this.currentPage
      }
    },
    async showMore (page) {
      this.isLoadingButton = true
      const data = await getProductsCategory(this.$route.params.slug, page, this.sort, this.selectedFilters)
      this.isLoadingButton = false
      this.currentPage = page
      this.products = [...this.products, ...data.products]
      const url = this.sort ? `/category/${this.$route.params.slug}/page/${page}?sort=${this.sort}` : `/category/${this.$route.params.slug}/page/${page}`
      history.replaceState(history.state, '', url)
    },
    updatePage (path) {
      if (path === this.$route.fullPath) {
        history.replaceState(history.state, '', path)
        this.products = this.startProducts
        this.currentPage = this.startPage
      } else {
        this.startProducts = []
        this.startPage = 1
      }
    }
  },
  mounted () {
    if (this.isNeedErrorRedirect(this.$route)) return
    this.getPreloadSubcategories(this.$route)
    if (this.$route.params.numberPage) this.currentPage = +this.$route.params.numberPage
    if (this.$route.query.sort) this.sort = this.$route.query.sort
    this.setProducts(this.$route, this.currentPage)
  },
  beforeRouteUpdate (to, from, next) {
    this.isNotFound = false
    if (this.isNeedErrorRedirect(to)) return
    if (this.$route.params.slug !== to.params.slug) {
      this.selectedFilters = {}
      this.sort = ''
      this.startProducts = []
    }
    if (to.query.sort !== from.query.sort) this.startProducts = []
    this.getPreloadSubcategories(to)
    to.params.numberPage ? this.setProducts(to, +to.params.numberPage) : this.setProducts(to)
    next()
  }
}
</script>
